import { useContext, useState, useEffect } from 'react';
import { Box, BoxProps, useBreakpointValue } from '@chakra-ui/react';
import SearchInput from './SearchComponents/SearchInput';
import SuggestedSearch from './SearchComponents/SuggestedSearch';
import { pixels } from '../theme';
import { useNavigate, useLocation } from 'react-router-dom';
// import useAxios from '../hooks/useAxios';
import { useRef } from 'react';

import { AppContext } from '../context/AppContext';
import { useLoading } from '../context/LoadingContext';
import { getUserLocation } from '../utilities/getUserLocation';

interface SearchBoxOptions extends BoxProps {
	suggestionBackground?: string;

	dontDoInitSearch?: boolean;

	onClose?: () => void;

	placeholder?: string;
}

const SearchBox = (props: SearchBoxOptions) => {
	// const { fetchData } = useAxios();
	const abortControllerRef = useRef<AbortController | null>(null);
	const apiUrl = process.env.REACT_APP_API_URL;
	const navigate = useNavigate();
	const location = useLocation();
	const [showSuggestedBox, setShowSuggestedBox] = useState(false);
	const context: any = useContext(AppContext);
	const {
		setSearchDetail,
		searchDetail,
		setSearchDetailLoading,
		// setLikeStatus,
		// likeSearches,
		searchPlaceHolder,
		setSearchPlaceHolder,
		setCurrentVideoIndex
	} = context;
	const { setIsLoading } = useLoading();

	const handleSearchInputFocus = () => {
		setShowSuggestedBox(false);
	};

	// const initializeLikeStatus = async (videos: any[]) => {
	// 	interface Video {
	// 		id: number;
	// 		liked: boolean;
	// 	}

	// 	let tempLikeStatus: Video[] = [];
	// 	let index = 0;

	// 	for (let i = 0; i < videos.length; i++) {
	// 		let search_status = false;

	// 		for (let k = 0; k < likeSearches.length; k++) {
	// 			if (likeSearches[k]['url']?.includes(videos[i]['video_id']) || likeSearches[k]['url'] === videos[i]['video_url']) {
	// 				search_status = true;
	// 				break;
	// 			}
	// 		}
	// 		let video: Video = {
	// 			id: index,
	// 			liked: search_status
	// 		};
	// 		tempLikeStatus.push(video);
	// 		index++;
	// 	}
	// 	setLikeStatus((prevLikeStatus: any = []) => {
	// 		if (Array.isArray(prevLikeStatus)) {
	// 			return [...prevLikeStatus, ...tempLikeStatus];
	// 		}
	// 		return tempLikeStatus;
	// 	});


	// };

	const handleSearchInputBlur = async (query: any) => {
		console.log('query ====>', query);

		setTimeout(() => {
			setShowSuggestedBox(false);
		}, 500);

		if (!query) return;

		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}

		abortControllerRef.current = new AbortController();
		const { signal } = abortControllerRef.current;

		localStorage.setItem('query', query);

		console.log('Setting loading states to true');
		setSearchDetailLoading(true);
		setIsLoading(true);


		if (location.pathname === '/') {
			navigate(`/video_search?query=${query}`);
		}
		const user_loc = await getUserLocation().catch(() => null);
		try {
			setSearchDetail([]);
			// setLikeStatus([]);
			setCurrentVideoIndex(0);
			console.log('Starting API call...');
			// const { data, statusCode } = await fetchData({
			// 	method: 'GET',
			// 	url: `ai/search-detail?search_slug=${query}`,
			// 	options: {
			// 		data: {},
			// 		headers: { 'Content-Type': 'application/json' },
			// 	},
			// 	auth: false,
			// });
			// const ws = new WebSocket(appUrl + "v1/video-highlights/stream");

			fetch(apiUrl + "v2/video-highlights/stream", {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ query: { title: query },user_loc: user_loc }),
				signal
			})
				.then(response => {
					if (!response.ok) {
						throw new Error(`HTTP error! status: ${response.status}`);
					}
					return response;
				})
				.then(response => {
					if (!response.body) {
						throw new Error('Response body is null');
					}
					const reader = response.body.getReader();
					let buffer = ""
					const readChunk = () => {
						reader.read().then(({ value }) => {
							const chunk = new TextDecoder('utf-8').decode(value);
							buffer += chunk;

							if (buffer === "🥚") {
								console.log('Done reading stream');
								setSearchDetailLoading(false);
								setIsLoading(false);
								return
							}

							if (buffer.indexOf("✅") !== -1) {
								let ind = buffer.indexOf("✅")
								let comp_json = buffer.slice(0, ind)
								buffer = buffer.slice(ind + 1)

								// console.log(`Received chunk: ${comp_json}`);
								const newData = JSON.parse(comp_json);
								const filteredData = {
									videos: newData.videos.filter(
										(video: any) => video.title && video.video_url
									)
								};
								setSearchDetail((prevState: { videos: any; }) => {
									const prevVideos = Array.isArray(prevState.videos) ? prevState.videos : [];
									const updatedVideos = filteredData.videos.reduce((acc: any[], video: any) => {
										const existingIndex = acc.findIndex(v => v.video_url === video.video_url);
										if (existingIndex !== -1) {
											acc[existingIndex] = video; // Replace existing video
										} else {
											acc.push(video); // Add new video
										}
										return acc;
									}, [...prevVideos]);
									console.log('updatedVideos', updatedVideos);
									return {
										videos: updatedVideos,
									};
								}, () => {
									console.log('searchDetail', JSON.stringify(searchDetail));
								});

								// setSearchDetail((prevState: { videos: any; }) => {
								// 	const prevVideos = Array.isArray(prevState.videos) ? prevState.videos : [];
								// 	console.log('prevVideos', prevVideos);
								// 	return {
								// 		videos: [...prevVideos, ...filteredData.videos],
								// 	};
								// }, () => {
								// 	console.log('searchDetail', JSON.stringify(searchDetail));
								// });
								// }
								// initializeLikeStatus(filteredData?.videos);
								if (filteredData?.videos.length !== 0) {
									setSearchDetailLoading(false);
									setIsLoading(false);
								}

							}

							readChunk();
						}).catch(error => {
							if ((error as any).name === 'AbortError') {
								console.log('Previous request aborted');
							} else {
								console.error('Error reading stream:', error);
							}
						});
					};
					readChunk();
				})
				.catch(error => console.error('Error:', error));

			// console.log('API call completed. Response:', data);

			// if (statusCode === 401 || statusCode === 500) {
			// 	console.warn('Unauthorized or server error.');
			// 	setSearchDetailLoading(false);
			// 	setIsLoading(false);
			// 	return;
			// }

			setSearchPlaceHolder(query);
			// ws.onopen = () => {
			// 	// Send the query payload when the connection is opened
			// 	ws.send(JSON.stringify({ query: { title: query } }));
			// };

			// ws.onmessage = async (event) => {
			// 	const newData = JSON.parse(event.data);
			// 	console.log('newData', newData);
			// 	// const filteredData = {
			// 	// 		videos: newData.videos.filter(
			// 	// 			(video: any) => video.title && video.video_url
			// 	// 		)
			// 	// 	};
			// 	// if(!newData.error){
			// 	if (newData.error) {
			// 		setSearchDetailLoading(false);
			// 		setIsLoading(false);
			// 		navigate("/");
			// 		console.log("Error in API call");
			// 	}
			// 	else {
			// 		const filteredData = {
			// 			videos: newData.videos.filter(
			// 				(video: any) => video.title && video.video_url
			// 			)
			// 		};
			// 		console.log('filteredData', filteredData);

			// 		// console.log('data', data);
			// 		// setSearchDetail(filteredData);
			// 		await setSearchDetail((prevState: { videos: any; }) => {
			// 			const prevVideos = Array.isArray(prevState.videos) ? prevState.videos : [];
			// 			console.log('prevVideos', prevVideos);
			// 			return {
			// 				videos: [...prevVideos, ...filteredData.videos],
			// 			};
			// 		}, () => {
			// 			console.log('searchDetail', JSON.stringify(searchDetail));
			// 		});
			// 		// }
			// 		initializeLikeStatus(filteredData?.videos);
			// 		if (filteredData?.videos.length !== 0) {
			// 			setSearchDetailLoading(false);
			// 			setIsLoading(false);
			// 		}
			// 	}
			// }
			// ws.onerror = (error) => {
			// 	console.error('WebSocket error:', error);
			// };

			// ws.onclose = () => {
			// 	console.log('WebSocket connection closed');
			// };

			// return () => {
			// 	ws.close();
			// };

			// const filteredData = {
			// 	videos: data.videos.filter(
			// 		(video: any) => video.title && video.video_url
			// 	),
			// 	city: data.city
			// };
			// console.log('filteredData', filteredData);

			// console.log('data', data);
			// setSearchDetail(filteredData);


			// initializeLikeStatus(filteredData?.videos, filteredData['city']);
		} catch (error) {
			if ((error as any).name === 'AbortError') {
				console.log('Fetch aborted');
			} else {
				console.error('Error during API call:', error);
			}
		} finally {
			// console.log('Setting loading states to false');
			// setSearchDetailLoading(false);
			// setIsLoading(false);
		}
	};


	useEffect(() => {

		if (props.dontDoInitSearch) return;
		const searchParams = new URLSearchParams(location.search);
		const queryParam = searchParams.get('query');

		if (queryParam) {
			console.log('query param', queryParam);
			setSearchPlaceHolder(queryParam);
			handleSearchInputBlur(queryParam);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.search]);

	const bg = useBreakpointValue({
		base:
			location.pathname === '/'
				? 'rgba(255, 255, 255, 0.93)'
				: 'rgba(39, 37, 41, 0.53)',
		md: 'rgba(255, 255, 255, 0.93)',
		lg:
			location.pathname === '/'
				? 'rgba(255, 255, 255, 0.93)'
				: 'rgba(39, 37, 47, 0.53)',
	});

	const inputFontColor = useBreakpointValue({
		base: location.pathname === '/' ? 'black' : 'white',
		md: 'black',
		lg: location.pathname === '/' ? 'black' : 'white',
	});

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				mt: { 'base': pixels['40pixels'], '2xl': pixels['50pixels'] },
				w: '100%',
				zIndex: { base: 72, lg: 2 },
				position: 'absolute',
				backdropFilter: 'blur(10px)',
				bg: bg,
			}}>
			<SearchInput
				onSearch={(value?: any) => handleSearchInputBlur(value)}
				onBlur={() => {
					setTimeout(() => {
						setShowSuggestedBox(false);
					}, 200);
				}}
				onFocus={handleSearchInputFocus}
				bg={bg}
				inputFontColor={inputFontColor}
				placeholder={searchPlaceHolder}
				onClose={props?.onClose}
				dontDoInitSearch={props?.dontDoInitSearch}
			/>
			{showSuggestedBox && (
				<Box
					zIndex={100}
					w={{
						'base': '90%',
						'lg': pixels['894pixels'],
						'2xl': pixels['1100pixels'],
					}}
					mt={{ base: 0, lg: pixels['20pixels'] }}
					position={'absolute'}
					top={{ base: pixels['30pixels'], lg: '50px' }}>
					<SuggestedSearch
						handleSearch={handleSearchInputBlur}
						background={props.suggestionBackground}
						suggestedSearchItems={[
							'Top things to do in Thailand...',
							'Best places to visit in Southern Europe...',
							'Best scuba diving places...',
							'Best adventure destinations...',
							'Top things to do in Vietnam...',
						]}
					/>
				</Box>
			)}
		</Box>
	);
};

export default SearchBox;
