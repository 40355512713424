import { useState } from 'react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getUserLocation } from '../utilities/getUserLocation';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

interface UseAxiosOptions extends AxiosRequestConfig<any> { }

interface FetchDataParams {
	method: HttpMethod;
	url: string;
	options?: UseAxiosOptions;
	auth?: boolean;
}

interface FetchDataResponse {
	data: any;
	statusCode: number;

	error?: any;
}

const useAxios = () => {

	const [data, setData] = useState<any>(null);
	const [error, setError] = useState<any>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [statusCode, setStatusCode] = useState<number>(0); // Added

	const baseUrl = process.env.REACT_APP_API_URL;
	const fetchData = async ({
		method,
		url,
		options,
		auth,
	}: FetchDataParams): Promise<FetchDataResponse> => {
		try {
			setLoading(true);

			let config: AxiosRequestConfig = { method, url: baseUrl + url };
			if (options) {
				config = { ...config, ...options };
			}
			if (auth) {
				const token = localStorage.getItem('token');
				if (token) {
					config.headers = {
						...config.headers,
						Authorization: `Bearer ${token}`,
					};
				} else {
					return {
						data: null,
						error: 'No token',
						statusCode: 401,
					};
					// throw new Error('No token found in local storage');
				}
			}
			if (method.toLowerCase() === 'post') {
				const user_loc = await getUserLocation().catch(() => null);
				config.data = {
					...config.data,
					user_loc: user_loc,
				};
			}

			const response: AxiosResponse = await axios(config);
			setData(response?.data);
			setStatusCode(response.status);
			console.log('data', response);
			return { data: response.data, statusCode: response.status, error: null };
		} catch (error: any) {
			console.log('errr data', error);
			setError(error?.response?.data?.message);
			return {
				data: null,
				error: error?.response?.data,
				statusCode: error.response ? error.response.status : 500,
			};
		} finally {
			setLoading(false);
		}
	};

	return { data, error, loading, fetchData, statusCode }; // Added statusCode
};

export default useAxios;
