import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import SavedVideoCard from "./SavedVideoCard";
import { SimpleGrid } from "@chakra-ui/react";

const SavedVideos = ({ data }) => {
  const context = useContext(AppContext);
  const { likeSearches } = context;
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const tempSavedVideos = [];

    for (let search of likeSearches) {
      // assuming the if country is empty then definately the city is other
      if (
        (!search.countries || Object.keys(search.countries).length === 0) &&
        data[0] === undefined
      ) {
        tempSavedVideos.push(search);
      } else if (
        search["countries"] &&
        typeof search["countries"] === "object"
      ) {
        // Iterate over the keys (country names) and values (city arrays)
        Object.entries(search["countries"]).forEach(
          ([countryName, cityList]) => {
            if (
              countryName === data[0] &&
              (cityList.includes(data[1]) ||
                (data[1] === "Other" && cityList.length === 0))
            ) {
              tempSavedVideos.push(search);
            }
          }
        );
      }
      //   else {
      //     const cityName =
      //       search["city"] === undefined ? "Other" : search["city"];
      //     if (
      //       search["country"] === bucketListCard[0] &&
      //       cityName === bucketListCard[1]
      //     ) {
      //       tempSavedVideos.push(search);
      //     }
      //   }
    }

    setVideos(tempSavedVideos);
  }, [likeSearches, data]);

  return (
    <SimpleGrid columns={2} spacing={7} rowGap={9}>
      {videos.map((video, index) => (
        <SavedVideoCard
          index={index}
          boxSize={"158px"}
          imageUrl={video["previewImage"]}
          videoUrl={video["url"]}
          title={video["title"]}
          video_metadata_id={video["video_metadata_id"]}
        />
      ))}
    </SimpleGrid>
  );
};

export default SavedVideos;
