import { Box, VStack, useDisclosure } from "@chakra-ui/react";
import Header from "./Header";
import ModalWithBackdrop from "../ModalWithBackdrop"; // Import the modal with backdrop
import SigninComponent from "../SigninComponent"; // Import the signin component
import useAuthRouteProtector from "../../hooks/useAuthRouteProtector";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Collection from "../../pages/Collection";
import ItineraryFooter from "../CreateItinary/ItineraryFooter";
import CollectionSearch from "../Collection-flow/CollectionSearch";
import SearchBar from "../SearchComponents/SearchBar";
const LandingPage: React.FC = () => {
  const { isAuthenticated } = useAuthRouteProtector();
  // Modal control for the Signin modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  const alreadyUser = useLocation().state?.alreadyUser;
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (alreadyUser) {
      onOpen();
    }
    // eslint-disable-next-line
  }, [alreadyUser]);

  const handleSearchInputBlur = async (query: any) => {
    localStorage.setItem('query', query);
    if (location.pathname === '/') {
      return navigate(`/video_search?query=${query}`);
    }
  };

  return (
    <>
      {/* Signin Modal */}
      <ModalWithBackdrop isOpen={isOpen} onClose={onClose}>
        <SigninComponent onClose={onClose} />
      </ModalWithBackdrop>

      {/* Main page content */}
      <VStack
        bg="black"
        h={"100%"}
        w={"100%"}
        overflowX={"hidden"}
        overflowY={"auto"}
        minH={"100dvh"}
      >
        <Box
          position={"fixed"}
          top={0}
          left={0}
          w={"100vw"}
          h={"80vh"}
          zIndex={1}
          bg={"linear-gradient(180deg, #252128 0%, rgba(36, 29, 50, 0) 100%)"}
        />
        {/* Header: Fixed position at the top */}
        <Box
          position="fixed"
          top={0}
          left={0}
          w="100vw"
          zIndex={10} // Keep the z-index high to be on top
        >
          <Header onOpenLogin={onOpen} isAuthenticated={isAuthenticated} />

        </Box>
        <VStack
          pt="19%"
          w={"100%"}
          pb={4}
          px={'5%'}
          zIndex={9}
          spacing={4}
          bg="#29252D"
          borderBottomRadius="33px"
        >
          <Box
            bgImage={"url('/images/assest.svg')"}
            bgSize="cover"
            bgPosition="center"
            mt={0}
            w={"100%"}
            h={"167px"}
            borderRadius={'24px'}
            mx={3}
            p={4}
          >
            <SearchBar onSearch={(value?: any) => handleSearchInputBlur(value)} />
          </Box>
          <Box
            bgImage={"url('/images/assest.svg')"}
            bgSize="cover"
            bgPosition="center"
            mt={0}
            h={"167px"}
            p={4}
            w={"100%"}
            pt={8}
            borderRadius={'24px'}
            mx={3}
          >
            <CollectionSearch />
          </Box>
          {/* <CollectionSearch /> */}
        </VStack>
        {/* Main content below the header */}
        <VStack zIndex={5} w="100%" spacing={3} pt={1}>

          <Collection />

        </VStack>
        <ItineraryFooter />
      </VStack>
    </>
  );
};

export default LandingPage;
