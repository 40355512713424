import { Box, Image, VStack, Text, Heading } from "@chakra-ui/react";

interface TripCardProps {
    tripName: string;
    description: string;
    date: string;
    thumbnail: string;
    price: number;
    onCardClick?: () => void;
}

const TripCard: React.FC<TripCardProps> = ({ tripName, description, date, thumbnail, price, onCardClick }) => {
    return (
        <VStack
            onClick={onCardClick}
            w={"158px"}
            h={"230px"}
            bg={'#FFFFFF'}
            borderRadius={"16px"}
            border={"1px solid #E5E5E5"}
        // _hover={{
        //     // boxShadow: "lg",
        //     transform: "scale(1.05)",
        //     transition: "all 0.3s ease-in-out",
        // }}
        >
            <Image
                src={thumbnail}
                alt={tripName}
                objectFit="cover"
                width="100%"
                h={"50%"}
                borderRadius={"16px"}
            // height="200px"
            />
            <VStack
                fontFamily={"Poppins"}
                color={"#424242"}
                spacing={1}
                ml={2}
                alignItems="flex-start"
            >
                <Text
                    fontWeight={700}
                    fontSize="13px"
                    lineHeight="20px"
                    letterSpacing="-0.5px"
                >
                    {tripName}
                </Text>
                <Text
                    fontWeight="500"
                    fontSize="12px"
                    lineHeight="13px"
                    letterSpacing="-0.5px"
                    noOfLines={1}
                >
                    {description}
                </Text>
                <Text
                    fontWeight="400"
                    fontSize="10px"
                    lineHeight="13px"
                    letterSpacing="-0.5px"
                >
                    {date}
                </Text>
                <Heading
                    fontWeight="700"
                    fontSize="20px"
                    lineHeight="30px"
                    letterSpacing="-0.83px"
                >
                    ${price}
                </Heading>
            </VStack>
        </VStack>
    );
}

export default TripCard;