import React from 'react';
import { Box, Image, VStack, Text, Flex } from '@chakra-ui/react';

interface DestinationCardProps {
    imageSrc: string;
    headingText: string;
    subheadingText: string;
    onClick?: () => void;  // Optional onClick handler
}

const DestinationCard: React.FC<DestinationCardProps> = ({
    imageSrc,
    headingText,
    subheadingText,
    onClick,  // Accept the onClick prop
}) => {
    return (
        <Box
            width="100%"
            height="26vh"
            position="relative"
            overflow="hidden"
            borderRadius="20px"
            border="1px solid #474545"
            cursor="pointer"  // Add a pointer cursor to indicate it's clickable
            onClick={onClick}
            // Add the onClick handler here
                        >
                                <Box
                                        width="full"
                                        height="full"
                                        position="absolute"
                                        top="0"
                                        left="0"
                                        zIndex={-1}
                                        bgGradient="linear-gradient(180deg, rgba(0, 0, 0, 0.73) 0%, rgba(0, 0, 0, 0) 60.45%)"
                                />
                                <Image
                                        src={imageSrc}
                                        alt={headingText}
                                        objectFit="cover"
                                        width="100%"
                                        height="100%"
                                        position="absolute"
                                        top="0"
                                        left="0"
                                        zIndex={-2}
                                />
            <Flex
                direction="column"
                justifyContent="space-between"
                position="absolute"
                top="0"
                bottom="0"
                left="0"
                right="0"
            >
                <VStack
                    pt={'27.61px'}
                    px={2}
                    alignItems="center">
                    <Text
                        color="#FFFFFFED"
                        lineHeight="19.7px"
                        fontFamily="Poppins"
                        fontSize="10px"
                        textAlign="center"
                        fontWeight={600}
                        letterSpacing="1.56px"
                    >
                        {subheadingText}
                    </Text>
                    <Text
                        color="#FFFFFFD4"
                        fontFamily="Poppins"
                        fontSize={'24px'}
                        letterSpacing="-1.02px"
                        textAlign="center"
                        zIndex={5}
                        mt={'-1vh'}
                        lineHeight="29.3px"
                        fontWeight={600}
                        // sx={{
                        //     backdropFilter: "blur(1.0422059297561646px)"
                        // }}
                    >
                        {headingText}
                    </Text>
                </VStack>
                <Image
                    alignSelf="center"
                    boxSize="60px"
                    pb={'12px'}
                    src={"/icons/explore.svg"}
                    alt="Explore"
                    m="4"  // Margin to push the button slightly away from the bottom edge
                />
            </Flex>
        </Box>
    );
}

export default DestinationCard;

