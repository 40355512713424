import { Image, Text, VStack, HStack, useBreakpointValue } from "@chakra-ui/react";
interface AlternatingCardProps {
    activities: any;
}
const AlternatingCard: React.FC<AlternatingCardProps> = ({
    activities,
}) => {
    const isMobile = useBreakpointValue({ base: true, md: false });
    return (
        <VStack spacing={6} align="stretch" flexDirection={isMobile ? "column" : "row"}>
            {activities.map((item: any, index: number) => {
                return (
                    <HStack

                        key={index}
                        spacing={4}
                        alignItems={'flex-start'}
                        // bg="gray.800"
                        p={4}
                        borderRadius="md"
                        boxShadow="md"
                        color="#FFFFFFD4"
                        flexDirection={["row", "column"]}
                    >
                        <Image
                            src={item?.thumbnail}
                            alt={item?.name}
                            border={'1.06px solid #313030'}
                            boxSize={{ base: "100px", md: "120px" }}
                            borderRadius="md"
                            objectFit="cover"
                            w={'40%'}

                        />
                        <VStack align={["center", "start"]} spacing={1} alignItems={'flex-start'} justifyContent={'left'} textAlign={'left'}>
                            <Text fontWeight="bold" fontSize="lg">
                                {item?.name}
                            </Text>
                            <Text fontSize="sm" >{item?.description}</Text>
                            <Text fontSize="sm" fontWeight="bold">
                                {item?.type && 'Type:'} {item?.preferred_for &&"Preferred For"} <Text as="span" fontWeight="normal">{item?.type}{item?.preferred_for}</Text>
                            </Text>
                            <Text fontSize="sm" fontWeight="bold">
                                {item?.cuisine && 'Cuisine:'} <Text as="span" fontWeight="normal">{item?.cuisine}</Text>
                            </Text>
                            <Text fontSize="sm" fontWeight="bold">
                                {item?.reasoning && 'Reasoning:'} <Text as="span" fontWeight="normal">{item?.reasoning}</Text>
                            </Text>
                            <Text fontSize="sm" fontWeight="bold">
                                Budget: <Text as="span" fontWeight="normal">{item?.costINR}</Text>
                            </Text>
                            {item?.booking_link && (
                                <Text color='#AE7CFF' fontWeight='600' fontSize='13px' onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    window.open(item?.booking_link, '_blank');
                                }}>
                                    Book here
                                </Text>
                            )}
                        </VStack>
                    </HStack>
                );
            })}
        </VStack>
    );
};

export default AlternatingCard;
