import React from 'react';
import { Box, Text, Flex, Icon, Button } from '@chakra-ui/react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import BookingCard from '../BookingCard';
import ImageGrid from './ImageGrid';
import AlternatingCard from './AlternatingCard';

interface Booking {
  bookingPlatform?: string;
  BookingName?: string;
  BookingAddress?: string;
  BookingCity?: string;
  bookingOf?: string;
  BookingImage?: string;
  checkInDate?: string;
  checkOutDate?: string;
}

interface GenericItineraryCardProps {
  title?: string;
  subtitle?: string;
  details?: string;
  locationFrom?: string;
  locationTo?: string;
  LinkText?: string;
  onLinkButtonClick?: () => void;
  Booking?: Booking; // Single Booking object
  accommodation?: any;
  type?: string;
  costINR?: string;
  activities?: any;
}

const GenericItineraryCard: React.FC<GenericItineraryCardProps> = ({
  title,
  subtitle,
  details,
  locationFrom,
  locationTo,
  LinkText,
  onLinkButtonClick,
  Booking,
  accommodation,
  type,
  costINR,
  activities
}) => {
  return (
    <Box mt={4} mb={4}>
      {/* Conditionally render the title */}
      {title && (
        <Text size='lg' color={'#FFFFFFD4'}>
          {title}
        </Text>
      )}

      {/* Conditionally render the subtitle */}
      {subtitle && (
        <Text size='lg' noOfLines={2} color={'#FFFFFFD4'} mt={2}>
          {subtitle}
        </Text>
      )}

      {accommodation && (
        <ImageGrid
          accommodation={accommodation}
        />
      )}

      {/* Conditionally render the details */}
      {details && (
        <Text
          size={'13px'}
          mt={'24px'}
          color={'#FFFFFFA1'}
          lineHeight={'19px'}
          fontWeight={'medium'}
        >
          {details}
        </Text>
      )}

      {/* Conditionally render the location information */}
      {(locationFrom || locationTo) && (
        <Box mt={4} ml={'0'}>
          {locationFrom && (
            <>
              <Text fontSize='13px' color="#FFFFFFA1 " mb={2} mt={-2}>{`Type: ${type}`}</Text>
              <Text fontSize='13px' color="#FFFFFFA1 " mb={2}>{`Cost: ${costINR}`}</Text>
              <Flex alignItems="center">

                <Icon as={FaMapMarkerAlt} boxSize={4} color="#FFFFFFA1" />
                <Text ml={2} fontSize='13px' color="#FFFFFFA1">
                  {locationFrom}
                </Text>
              </Flex>
              {locationTo && (
                <Box ml={2} borderLeft="1px dashed" borderColor="#FFFFFF21" height="20px" />
              )}
            </>
          )}
          {locationTo && (
            <Flex alignItems="center" mt={2}>
              <Icon as={FaMapMarkerAlt} boxSize={4} color="#FFFFFFA1" />
              <Text ml={2} fontSize='13px' color="#FFFFFFA1">
                {locationTo}
              </Text>
            </Flex>
          )}
        </Box>
      )}
      {/* Conditionally render the link button */}
      {LinkText && (
        <Button
          fontSize='13px'
          variant="link"
          maxH={'19px'}
          mt={4}
          px={["0", "0", "32px"]}
          py={["0px", "0px", "20px"]}
          bg={["none", "none", "#282629"]}
          fontWeight={600}
          borderRadius={["0", "0", "8px"]}
          color="#AE7CFF"
          onClick={onLinkButtonClick}
        >
          {LinkText}
        </Button>
      )}
      {activities?.length > 0 && (<AlternatingCard
        activities={activities}
      />)}
      {/* Conditionally render the Booking booking */}
      {Booking && (
        <Box mt={4}>
          <BookingCard
            bookingOf={Booking.bookingOf}
            bookingPlatform={Booking.bookingPlatform}
            BookingName={Booking.BookingName}
            BookingAddress={Booking.BookingAddress}
            BookingCity={Booking.BookingCity}
            BookingImage={Booking.BookingImage}
            checkInDate={Booking.checkInDate}
            checkOutDate={Booking.checkOutDate}
            onViewClick={() => console.log(`View Button Clicked for ${Booking.BookingName}`)}
            onDownloadClick={() => console.log(`Download Button Clicked for ${Booking.BookingName}`)}
          />
        </Box>
      )}

      {/* Dashed bottom border */}


    </Box>
  );
};

export default GenericItineraryCard;
