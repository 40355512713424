import { Box, SimpleGrid, VStack, Text, Image } from '@chakra-ui/react';
import React from 'react';

interface ImageViewProps {
	thumbnail?: string;
	name?: string;
	address?: string;
	costINR?: string;
	booking_link?: string;
	linkUrl?: string;
	width?: string;
	height?: string;
}

interface ImageGridProps {
	accommodation: any;
}

const ImageView: React.FC<ImageViewProps> = ({
	thumbnail,
	name,
	address,
	costINR,
	booking_link,
	linkUrl,
	width,
	height,
}) => {
	return (
		<Box>
			<VStack align='start' spacing={0}>
				<Image
					mt='20px'
					mb='12px'
					src={thumbnail}
					borderRadius='12px'
					objectFit='cover'
					w={width}
					h={height}
				/>
				{name && (
					<Text
						color='#FFFFFFA1'
						fontWeight='medium_bold'
						fontSize='13px'
						maxW={'140px'}>
						{name}
					</Text>
				)}
				{address && costINR
					&& (
						<Text color='#FFFFFF54' fontSize='11px' fontWeight='medium' noOfLines={1}>
							{address} {costINR}
						</Text>
					)}

				{booking_link && (
					<Text color='#AE7CFF' fontWeight='600' fontSize='13px' w={width} onClick={(e) =>{
						e.preventDefault();	
						e.stopPropagation();
						window.open(booking_link, '_blank');
					}}>
						Book here
					</Text>
				)}
			</VStack>
		</Box>
	);
};

const ImageGrid: React.FC<ImageGridProps> = ({ accommodation }) => {
	const isSingleImage = accommodation?.length === 1;

	return (
		<Box>
			{isSingleImage ? (
				<ImageView {...accommodation?.[0]} width='full' height='160px' />
			) : (
				<SimpleGrid columns={[2, 2, 6]} spacing={'20px'} mx={1}>
					{accommodation?.map((imageProps: any, index: number) => (
						<ImageView
							key={index}
							{...imageProps}
							width='175px'
							height='120px'
						/>
					))}
				</SimpleGrid>
			)}
		</Box>
	);
};

export default ImageGrid;
