import React from "react";
import { VStack, Text, Progress } from "@chakra-ui/react";
import { motion } from "framer-motion";

const MotionVStack = motion(VStack);
const MotionProgress = motion(Progress);


const Loader: React.FC = () => {
    const initialTime = 30;
    const [timeLeft, setTimeLeft] = React.useState(initialTime);

    React.useEffect(() => {
        if (timeLeft <= 0) return;
        const intervalId = setInterval(() => {
            setTimeLeft(prev => prev - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeLeft]);

    const progress = ((initialTime - timeLeft) / initialTime) * 100;

    return (
        <MotionVStack
            w="100vw"
            h="100vh"
            position="fixed"
            bg="black"
            zIndex={999999}
            alignItems="center"
            justifyContent="center"

        >
            <VStack
                w="70%"
                spacing="20px"
                p={6}
                borderRadius="md"
                boxShadow="lg"
            >
                <MotionProgress
                    w="100%"
                    size="sm"
                    colorScheme="gray"
                    value={progress}
                    borderRadius="md"
                    initial={{ opacity: 0.5 }}
                    animate={{ opacity: 1, scale: [1, 1.02, 1] }}
                    transition={{ duration: 1.5, repeat: Infinity, ease: "easeInOut" }}
                // sx={{
                //     background: "linear-gradient(90deg, #444, #888, #444)",
                //     backgroundSize: "200% 100%",
                //     animation: `${shimmer} 2s infinite linear`,
                // }}
                />
                <Text
                    color="rgba(255, 255, 255, 0.80)"
                    textAlign="center"
                    fontFamily="Poppins"
                    fontSize="16px"
                    fontWeight="500"
                >
                    Curating your next adventure... Hang tight!
                </Text>
            </VStack>
        </MotionVStack>
    );
};

export default Loader;
