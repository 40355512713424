export const getUserLocation = (): Promise<{ latitude: number; longitude: number }> => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error("Geolocation is not supported by your browser."));
      } else {
        // Using watchPosition to improve compatibility across devices (including Android PWA, iOS PWA, iOS Safari, and other browsers)
        const watchId = navigator.geolocation.watchPosition(
          (position) => {
            navigator.geolocation.clearWatch(watchId);
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            reject(error);
          },
          { enableHighAccuracy: true, maximumAge: 0, timeout: 10000 }
        );
      }
    });
  };