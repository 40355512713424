import React from 'react';
import { Card, Heading, HStack, VStack, Button, Image, Box } from "@chakra-ui/react";

interface ChatCardProps {
  imageSrc: string;
  altText: string;
  title: string;
  buttonText: string;
  onButtonClick?: () => void;
  onCardClick?: () => void;
  city?: string;
  country?: string;
}

const ChatCard: React.FC<ChatCardProps> = ({
  imageSrc,
  altText,
  title,
  buttonText,
  onButtonClick,
  onCardClick,
  city,
  country,
}) => {
  return (
    <Card
      borderRadius="18px"
      p={0}
      w="240px"
      h="268px"
      mb="12px"
      mx={"12px"}
      border="1px solid #E5E5E5"
      onClick={onCardClick}
      overflow="visible"
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.15)" // Stronger shadow
    >

      {imageSrc ? (
        <Image
          src={imageSrc}
          alt={altText}
          borderRadius="18px 18px 18px 18px"
          objectFit="cover"
          p={"1px"}
          w="100%"
          h="70%" // Set a fixed height for the image
        />
      ) : (
        <Box
          borderRadius="18px 18px 18px 18px"
          bgColor="gray.200"
          w="100%"
          h="160px"
        />
      )}

      <HStack spacing={'auto'} mt={3} alignItems="flex-start" pb="20px" mx={5} justifyContent="space-between">
        <VStack spacing={0} alignItems="flex-start">
          <Heading
            fontSize="12px" // Keep font size as specified
            fontWeight={700}
            color="#424242"
            noOfLines={2}
            maxW="120px" // Limit width to avoid overflow
          >
            {title || <Box w="100px" h="12px" bgColor="gray.200" borderRadius="4px" />}
          </Heading>
          <Heading
            fontSize="11px" // Keep font size as specified
            fontWeight={400}
            color="#424242"
            noOfLines={2}
            maxW="120px" // Limit width to avoid overflow
          >
            {`${city??""}, ${country??""}` || <Box w="120px" h="12px" bgColor="gray.200" borderRadius="4px" />}
          </Heading>
        </VStack>

        {buttonText ? (
          <Button
            variant="link"
            color="#7539D7"
            fontWeight={700}
            fontSize="12px"
            onClick={(e) => {
              e.stopPropagation(); // Prevents card click
              onCardClick?.();
            }}
          >
            {buttonText}
          </Button>
        ) : (
          <Box w="60px" h="12px" bgColor="gray.200" borderRadius="4px" />
        )}
      </HStack>
    </Card>
  );
};

export default ChatCard;
