import { VStack, Text, Button, Flex, Input } from "@chakra-ui/react";
import PhoneInput from 'react-phone-number-input';
interface DetailProps {
  label: string;
  value: string;
  buttonText: string;
  editMode?: boolean;
  onEditClick: () => void;
  onChange?: (value: React.ChangeEvent<HTMLInputElement> | string) => void;

}

const Detail: React.FC<DetailProps> = ({ label, value, buttonText, editMode, onEditClick, onChange }) => {
  return (
    <VStack
      borderRadius="12px"
      mx="30px"
      py="18px"
      px="30px"
      w="360px"
      mb={"10px"}
      minH={'73px'}
      border="1px solid #FFFFFF12"
      alignItems="space-between"
      maxW="100vw"
      zIndex={5}
    >
      <Flex justifyContent="space-between" width="100%">
        <Flex flexDir="column" flex="1">
          <Text
            fontFamily="Poppins"
            fontSize="13px"
            fontWeight="500"
            lineHeight="20px"
            letterSpacing="-0.465063214302063px"
            textAlign="left"
            color="#FFFFFF54"
          >
            {label}
          </Text>
            {editMode ? (
            label.toLowerCase() === "phone" ? (
              <PhoneInput
              style={{
                border: 'none',
                height: '44px',
                // backgroundColor: 'grey',
                // color: 'white'
              }}
              placeholder=''
              value={value}
              onChange={(newPhone: string) => {
                if (onChange) onChange(newPhone);
              }}
              defaultCountry='IN'
              />
            ) : (
              <Input
              value={value}
              onChange={(e) => {
                if (onChange) onChange(e);
              }}
              fontFamily="Poppins"
              fontSize="16px"
              fontWeight="500"
              lineHeight="17px"
              letterSpacing="-0.47px"
              textAlign="left"
              color="white"
              bgColor="grey"
              border="none"
              borderBottom="1px solid #FFFFFFED"
              _focus={{ outline: "none" }}
              placeholder=''
              />
            )
            ) : (
            <Text
              fontFamily="Poppins"
              fontSize="16px"
              fontWeight="500"
              lineHeight="20px"
              letterSpacing="-0.47px"
              textAlign="left"
              color="#FFFFFFED"
              maxW={'250px'}
              isTruncated={label !== "Traveler Details"}
            >
              {value}
            </Text>
            )}
        </Flex>
        <Button
          w="23px"
          h="17px"
          variant="link"
          fontFamily="Poppins"
          fontSize="13px"
          fontWeight="500"
          lineHeight="17px"
          letterSpacing="-0.465063214302063px"
          textAlign="left"
          color="#AE7CFF"
          onClick={onEditClick}
        >
          {buttonText}
        </Button>
      </Flex>
    </VStack>
  );
};

export default Detail;
