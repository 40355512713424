import {
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Box,
  Flex,
  VStack,
  Text,
  Image
} from "@chakra-ui/react";
import { useContext, useState } from "react";
// import { useNavigate } from "react-router-dom";

import { ArrowRightIcon } from "@chakra-ui/icons";
import { AppContext } from "../../context/AppContext";
import useTripService from "../../services/tripService";


const InputField: React.FC = () => {
  const context: any = useContext(AppContext);
  const { onBehalf, setIsChatLoading } = context;
  const [inputValue, setInputValue] = useState("");
  const { fetchConversationResponse } = useTripService();

  const handleSubmit = async () => {
    if (inputValue.trim() !== "") {
      const message = inputValue;
      setInputValue("");
      setIsChatLoading(true);
      await fetchConversationResponse(message);
      setIsChatLoading(false);
    }
    else
      return;
  };

  return (
    <Box
      borderRadius={"99px"}
      boxShadow="lg"
      overflow="hidden"
      w={'full'}
      border="1px solid #16181B12"
    >
      {onBehalf?.title && (
        <Flex py={6} px={4} bg={"#F7F8F9"} gap={"5%"}>
          <Image
            src={onBehalf?.imageUrl}
            maxH={"50px"}
            boxSize={"18%"}
            borderRadius={"20%"}
          />
          <VStack alignItems={"flex-start"} spacing={0}>
            <Text
              fontFamily="Poppins"
              fontSize="14px"
              fontWeight="700"
              lineHeight="20px"
              letterSpacing="-0.5px"
              textAlign="center"
              color={"#424242"}
            >
              {onBehalf?.title}
            </Text>
            <Text
              fontFamily="Poppins"
              fontSize="13px"
              fontWeight="400"
              lineHeight="20px"
              letterSpacing="-0.5px"
              textAlign="center"
              color={"#424242"}
            >
              {onBehalf?.subtitle}
            </Text>
          </VStack>
        </Flex>
      )}
      <InputGroup w={"100%"}>
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={'Type a query ....'}
          borderRadius={"99px"}
          bg="#FFFFFF"
          _placeholder={{ color: "#42424254" }}
          boxShadow={"0px 48px 100px 0px #110C2E26"}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
              (e.target as HTMLInputElement).blur();
            }
          }}
        />
        <InputRightElement>
          <IconButton
            aria-label="Send button"
            icon={<ArrowRightIcon />}
            borderRadius="full"
            variant="ghost"
            _hover={{ bg: "gray.100" }}
            onClick={handleSubmit}
            isDisabled={context.isChatLoading}
          />
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};

export default InputField;