import { useState, useRef, useEffect, useCallback } from "react";
import { Box, Text, Link, BoxProps } from "@chakra-ui/react";

interface CollapsibleTextProps extends BoxProps {
  content: string;
  maxLines?: number; // Max number of lines before showing "Read more"
}

const CollapsibleText: React.FC<CollapsibleTextProps> = ({ content, maxLines = 3, ...props }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [shouldShowToggle, setShouldShowToggle] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const toggleExpand = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsExpanded((prev) => !prev);
  };

  // Check for overflow using scrollHeight and clientHeight
  const checkOverflow = useCallback(() => {
    if (textRef.current) {
      const lineHeight = parseFloat(
        window.getComputedStyle(textRef.current).lineHeight
      );
      const maxHeight = maxLines * lineHeight;
      setShouldShowToggle(textRef?.current?.scrollHeight > maxHeight + 1);
    }
  }, [maxLines]);

  // Collapse when clicking outside the container
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow); // Recheck on window resize
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [content, checkOverflow]);

  return (
    <Box ref={containerRef} {...props}>
      <Text
        ref={textRef}
        sx={{
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
          WebkitLineClamp: isExpanded ? "none" : maxLines,
        }}
      >
        {content}
      </Text>
      {shouldShowToggle && (
        <Link fontSize="sm" fontWeight={600} onClick={toggleExpand} display="block">
          {isExpanded ? "Show less" : "Read more"}
        </Link>
      )}
    </Box>
  );
};

export default CollapsibleText;
