import { Container, Avatar } from "@chakra-ui/react";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
// import { VideoSliderContext } from "../context/VideoSliderContext";
import CollapsibleText from "./Text/CollapsibleText";

const MoreFromInfluecerCard = (props) => {
  const { placeName, imageUrl, index, boxSize, width } = props;
  const { setCurrentVideoIndex } = useContext(AppContext);
  // const { setPlaying } = useContext(VideoSliderContext);
  console.log("image url", imageUrl);
  return (
    <Container flexShrink={"0"} width={width} p={"5px"} m={"0px"}>
      {true && (
        <Avatar
          name={placeName || "title"}
          boxSize={boxSize}
          borderRadius={"10px"}
          // bgImage={`url(${imageUrl})`}
          src={imageUrl}
          onClick={async () => {
            await setCurrentVideoIndex(index);
            await window.scrollTo({
              top: 0,
              behavior: "smooth", // This makes the scroll smooth
            });
            // await setPlaying(true);
            if (props?.onClose) {
              props.onClose();
            }
          }}
          // bgSize='cover'
          // bgPosition='center'
          // position='relative'
          display="flex"
        >
          <button
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "1",
              cursor: "pointer",
              background: "transparent",
              border: "none",
            }}
          >
            <img
              src="/icons/playVideo.svg" // Path to your SVG file relative to the public folder
              alt="SVG Button"
              width="50"
              height="50"
            />
          </button>
        </Avatar>
      )}
      <CollapsibleText
        w={"158px"}
        fontSize={"md"}
        pl={"5px"}
        maxW={"fit-content"}
        fontWeight={"sm"}
        mt={"5px"}
        color={"white"}
        lineHeight="17.55px"
        maxLines={3}
        content={placeName}
      />
    </Container>
  );
};

export default MoreFromInfluecerCard;
