import {
    Box,
    SimpleGrid,
    Heading
} from "@chakra-ui/react";
import DestinationCard from "../components/Collection-flow/DestinationCard";

import {  useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef } from "react";
import { AppContext } from "../context/AppContext";
import { useLoading } from "../context/LoadingContext";
import * as amplitude from "@amplitude/analytics-browser";
import { insertIntoDatabase } from "../services/apiService";
import { formatDateTime } from "../utilities/formatDateTime";
import { getUserLocation } from "../utilities/getUserLocation";

interface Story {
    title: string;
    city: string;
    country: string;
    continent: string;
    location: {
        lat: number;
        long: number;
    };
    image_attributions?: any;
    static_fields?: any[];
    follow_on_queries?: any;
    description: string;
    images?: string[];
    videos?: string[];
    tags: string[];
    otherDetail?: any[];
    google_maps_url?: string;
    social_links?: any;
    booking_details?: any;
}
const defaultStories: Story[] = [
    {
        title: "",
        city: "",
        country: "",
        continent: "",
        location: { lat: 0, long: 0 },
        description: "",
        tags: ["", ""],
        images: [""],
    },
];

const Collection: React.FC = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    // const toShow = process.env.REACT_APP_ENV as string;
    const navigate = useNavigate();
    const { setIsLoading } = useLoading();
    const context: any = useContext(AppContext);
    const {
        setIsCollection,
        setChatEntries,
        setTitle,
        setCurrChatId,
        setOnBehalf,
        setInfluencerData,
        setCollectionLoading,
        setCollectionContent,
        setStryIndex,
        userDetail,
        isCenterButtonClicked,
    } = context;


    const destinations = [
        {
            imageSrc: "/images/Iceland.png",
            subheadingText: "Northern lights spots",
            headingText: "Iceland",
            query: "Show me best spots to view Northern Lights in Iceland",
        },
        {
            imageSrc: "/images/Japan2.webp",
            subheadingText: "Cherry blossom",
            headingText: "Japan",
            query: "Best places to see cherry blossoms in Japan",
        },
        {
            imageSrc: "/images/Philipines.png",
            subheadingText: "Pristine beaches",
            headingText: "Philippines",
            query: "Best islands and beaches to visit in Philippines in April",
        },
        {
            imageSrc: "/images/Himalayas.png",
            subheadingText: "Epic treks",
            headingText: "Himalayas",
            query: "Best beginner treks in the Himalayas in April",
        },

        {
            imageSrc: "/images/NZ.png",
            subheadingText: "Autumn colours",
            headingText: "New Zealand",
            query: "Best things to do in New Zealand during autumn in April",
        },
        {
            imageSrc: "/images/Asia.png",
            subheadingText: "Luxury resorts",
            headingText: "North India",
            query: "Find me the 6 most bespoke, hidden luxury resorts in North India",
        },
    ];
    // :
    // [
    //     {
    //         imageSrc: "/images/Iceland.png",
    //         subheadingText: "Northern lights spots",
    //         headingText: "Iceland",
    //         query: "Show me best spots to view Northern Lights in Iceland"
    //     },
    //     {
    //         imageSrc: "/images/st.png",
    //         subheadingText: "Best winter festivals",
    //         headingText: "Europe",
    //         query: "Best music or arts festivals in Europe in winter"
    //     },
    //     {
    //         imageSrc: "/images/Nightlife.png",
    //         subheadingText: "Best of nightlife",
    //         headingText: "Japan",
    //         query: "Best hidden, bespoke nightlife districts in Japan"
    //     },
    //     {
    //         imageSrc: "/images/Asia.png",
    //         subheadingText: "Luxury resorts in",
    //         headingText: "North India",
    //         query: "Find me the 6 most bespoke, hidden luxury resorts in North India"
    //     },

    //     {
    //         imageSrc: "/images/Switzerland.png",
    //         subheadingText: "Enchanting",
    //         headingText: "Switzerland",
    //         query: "Tell me the best romantic spots in Switzerland to experience during the winters"
    //     },
    //     {
    //         imageSrc: "/images/Greece.png",
    //         subheadingText: "Best Christmas markets in",
    //         headingText: "Europe",
    //         query: "Best Christmas markets in Europe this winter"
    //     }
    // ];

    const setState = async (
        conversation_id: any,
        query: string,
        query_answer: string,
        entities: any
    ) => {
        setCurrChatId(conversation_id);
        setTitle(query);
        setOnBehalf({});
        setChatEntries([
            {
                type: "Text",
                role: "user",
                message: query,
                time: formatDateTime(new Date()),
                onBehalf: undefined,
            },
            {
                type: "TextR",
                role: "assistant",
                message: query_answer,
                time: formatDateTime(new Date()),
                entities: entities.length === 0 ? undefined : entities,
            },
        ]);
    };

    const formatMessage = async (
        query_answer: string,
        entities: any,
        query: string,
        isCollectionCard?: boolean
    ) => {
        const token = localStorage.getItem("token");
        const conversation_id =
            Date.now().toString(36) + Math.random().toString(36).substr(2);
        setState(conversation_id, query, query_answer, entities);
        if (token && !isCollectionCard) {
            const format = {
                token: token,
                conversation_id: conversation_id,
                title: query,
                imageUrl: "",
                content: {
                    type: "Text",
                    message: query,
                    role: "user",
                    time: formatDateTime(new Date()),
                    onBehalf: undefined,
                },
            };
            await insertIntoDatabase(format);

            const formt = {
                token: token,
                conversation_id: conversation_id,
                title: query,
                imageUrl: entities.length === 0 ? "" : entities[0].image,
                content: {
                    type: entities.length === 0 ? "Text" : "TextR",
                    message: query_answer,
                    role: "assistant",
                    time: formatDateTime(new Date()),
                    entities: entities.length === 0 ? undefined : entities,
                    onBehalf: undefined,
                },
            };
            await insertIntoDatabase(formt);
        }
    };

    const convertToStories1 = (item: any, image: any): any => {
        return {
            title: item.title,
            city: item.city,
            country: item.country,
            continent: item.continent,
            location: item?.location ?? { lat: 0, long: 0 },
            description: item.description,
            images: item.images
                ? item.images.length > 0
                    ? [image, ...item.images]
                    : [item.image]
                : [""],
            videos: undefined,
            tags: item.tags,
            image_attributions: item.image_attributions ?? undefined,
            static_fields: item?.static_fields,
            otherDetail: item.dynamic_fields,
            follow_on_queries: item?.follow_on_queries,
            google_maps_url: item?.google_maps_url,
            social_links: item?.social_links,
            booking_details: item?.booking_details,
        };
    };

    useEffect(() => {
        const handleScroll = () => {
            document.getElementById("search")?.blur();
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleCollectionCard = async (
        query: any,
        isCollectionCard?: boolean
    ) => {
        const user_loc = await getUserLocation().catch(() => null);
        let allEntities: any[] = [];
        setStryIndex(null);
        setIsCollection(true);
        setCollectionLoading(true);
        setIsLoading(true);

        fetch(apiUrl + "v2/collection/stream", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                title: query,
                user_detail: isCollectionCard ? undefined : userDetail ?? undefined,
                user_loc: user_loc,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response;
            })
            .then((response) => {
                if (!response.body) {
                    throw new Error("Response body is null");
                }
                const reader = response.body.getReader();
                let buffer = "";
                const readChunk = () => {
                    reader.read().then(async ({ value }) => {
                        const chunk = new TextDecoder("utf-8").decode(value);
                        buffer += chunk;

                        if (buffer === "🥚") {
                            console.log("Done reading stream");
                            setIsLoading(false);
                            return;
                        }

                        if (buffer.indexOf("✅") !== -1) {
                            let ind = buffer.indexOf("✅");
                            let comp_json = buffer.slice(0, ind);
                            buffer = buffer.slice(ind + 1);

                            // console.log(`Received chunk: ${comp_json}`);
                            const message = JSON.parse(comp_json);

                            if (message.entities) {
                                formatMessage(
                                    message.query_answer,
                                    message.entities,
                                    query,
                                    isCollectionCard
                                );
                                if (message?.entities?.length === 0) {
                                    navigate("/chat");
                                    setIsLoading(false);
                                } else {
                                    allEntities = message.entities.map((entity: any) => ({
                                        ...entity,
                                        images: entity.image,
                                    }));
                                    console.log("allEntities", allEntities);
                                    await setCollectionContent(
                                        Array(allEntities?.length).fill(defaultStories)
                                    );
                                    await setInfluencerData(Array(allEntities.length).fill([]));
                                    const contxt = {
                                        query: query,
                                        answer: message.answer,
                                    };
                                    navigate("/story-carousel", {
                                        state: {
                                            clickedIndex: 0,
                                            isCollectionCard: isCollectionCard ?? false,
                                            iscollectionLoading: true,
                                            allEntities: allEntities, // pass the array with title and image
                                            query: query, // pass the array with title and image
                                            context: contxt,
                                        },
                                    });
                                    setIsLoading(false);
                                }
                            }
                            if (message.entities_details) {
                                setCollectionContent((prevContent: any) => {
                                    // Create a copy of the previous state
                                    const updatedContent = [...prevContent];

                                    // Update only the specific indices for matching titles
                                    allEntities.forEach((entity, index) => {
                                        const matchingDetail = message.entities_details.find(
                                            (detail: any) => detail.title === entity.title
                                        );
                                        if (matchingDetail) {
                                            updatedContent[index] = convertToStories1(
                                                matchingDetail.entity_detail,
                                                entity.images
                                            );
                                        }
                                    });

                                    return updatedContent; // Return the updated state
                                });
                            }
                        }
                        readChunk();
                    });
                };
                readChunk();
            })
            .catch((error) => {
                console.error("Error fetching stream:", error);
                setIsLoading(false);
            });
    };



    const searchBarRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (isCenterButtonClicked && searchBarRef.current) {
            searchBarRef.current.focus();
        }
    }, [isCenterButtonClicked]);

    return (
        <Box
            // mt={"21%"}
            minH="100vh"
            w="100vw"
            // onScroll={() => setShowSuggestedBox(false)}
            overflow="hidden"
        >

            <Box position="relative" zIndex={1} px="12px">
                {/* {toShow === "DEV" && */}
                {/* } */}
                <Heading
                    fontFamily="Poppins"
                    fontWeight="700"
                    fontSize="16px"
                    lineHeight="21.28px"
                    letterSpacing="0px"
                    color={"#FFFFFF"}
                    mb={4}
                    ml={1}
                >
                    Browse trending destinations
                </Heading>
                <Box mb="20%">
                    <SimpleGrid
                        columns={2}
                        spacing={3}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        {destinations.map((destination, index) => (
                            <DestinationCard
                                key={index}
                                onClick={async () => {
                                    amplitude.track("collection_clicked", {
                                        type: "card",
                                        query_name: destination.query,
                                        logged: localStorage.getItem("token") ? true : false,
                                    });
                                    handleCollectionCard(destination.query, true);
                                }}
                                imageSrc={destination.imageSrc}
                                headingText={destination.headingText}
                                subheadingText={destination.subheadingText}
                            />
                        ))}
                    </SimpleGrid>
                </Box>
            </Box>
        </Box>
    );
};

export default Collection;
