import React from 'react';
import { IconButton, Box, Button, Flex, HStack, Image, Text } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import TripCard from '../components/CreateItinary/TripCard';
import TripDetail from '../components/CreateItinary/TripDetail';
// import data from '../components/CreateItinary/data.json';

const Itinerary: React.FC = () => {
    const navigation = useNavigate();
    const location = useLocation();
    const destination = location?.state?.itinerary?.trip_itinerary;
    const itinerary = location?.state?.itinerary;

    console.log("destination", destination);
    const handleOnFollowUp = () => {
        console.log('Ask a follow-up button clicked');
    }

    if (!destination) {
        return (
            <Box
                bg='linear-gradient(118.03deg, #272529 5.68%, #111012 59.38%)'
                minH='100vh'
                w='100vw'
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <Box color='white' fontSize='xl'>Destination not found</Box>
            </Box>
        );
    }

    return (
        <Box
            bg={[
                '#141315',
                '#141315',
                'linear-gradient(118.03deg, #272529 5.68%, #111012 59.38%)'
            ]}
            minH='100vh'
            w='100vw'
            position='relative'
            overflow='hidden'
        >
            <Box
                mt={["0", "0", "4.5vh"]}
                mb={"8vh"}
                height={'auto'}
                pl={['0px', '0px', '18%']}
                pr={['0px', '0px', '17%']}
            >
                <Flex justify='space-between' align='center' maxH='68px' p='16px' visibility={["visible", "visible", "hidden"]}>
                    <Box>
                        <IconButton
                            aria-label='Back'
                            icon={<Image src="/icons/Back_Icon_White.svg" alt="back" />}
                            colorScheme='gray'
                            variant={'link'}
                            size='lg'
                            onClick={() => {
                                navigation(-1);
                            }}
                        />
                    </Box>
                    <HStack spacing='10px'>
                        <Button fontSize='13px' fontWeight='600' color='#AE7CFF' variant='link'>
                            + Save Itinerary
                        </Button>
                        <Button bg='inherit' variant='link'>
                            <img src={'/icons/share3.svg'} alt='share Icon' />
                        </Button>
                    </HStack>
                </Flex>
                <TripCard
                    title={destination?.title}
                    dates={destination?.dates}
                    number_of_people={destination?.number_of_people}
                    onEdit={() => {
                        console.log('Edit button clicked');
                    }}
                    onAddTickets={() => {
                        console.log('Add tickets button clicked');
                    }}
                />
                <TripDetail
                    title={destination?.title}
                    subtitle={destination?.subtitle}
                    description={destination?.description}
                    destination={destination}
                    itinerary={itinerary}
                />
            </Box>
            <Flex
                position='fixed'
                bottom='2vh'
                left='50%'
                transform='translateX(-50%)'
                zIndex={1000000}
                bg={'#252427D4'}
                w={'fit-content'}
                borderRadius={'99px'}
                h={'56px'}
                alignItems={'center'}
                border='linear-gradient(92.73deg, #8B8B8B 6.17%, #202020 97.11%),
        linear-gradient(272.54deg, rgba(0, 0, 0, 0) 8.68%, #614C75 99.06%)'>
                <Button
                    py={'18.5px'}
                    ml={'-6px'}
                    leftIcon={
                        <Image
                            src='/icons/Centre_Button.svg'
                            alt='icon'
                            boxSize='42.86'
                            border='0.3px solid linear-gradient(112.57deg, #FBF7F7 4.82%, #2E2929 104.38%)'
                        />
                    }
                    variant={'ghost'}
                    _hover={{ bg: 'none' }}
                    onClick={handleOnFollowUp}>
                    <Text
                        bgGradient='linear-gradient(90deg, #FFFFFF 63.24%, #666262 115.52%)'
                        bgClip={'text'}
                        fontFamily={'Poppins'}
                        fontSize={'15px'}
                        fontWeight={600}
                        letterSpacing={'=-0.56px'}>
                        {'Ask a follow-up'}
                    </Text>
                </Button>
            </Flex>
        </Box>
    );
};

export default Itinerary;
