import React, { useContext } from 'react';
import { Box, Divider, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
// import data from './data.json'; // Import the JSON data
import SearchBar from '../SearchComponents/SearchBar';
import ItineraryCardLayout from './ItineraryCardLayout';
import { AppContext } from '../../context/AppContext';


const ListItinerary: React.FC = () => {
	const navigate = useNavigate();
	const context: any = useContext(AppContext);
	const { itinerary } = context;


	const toShow = process.env.REACT_APP_ENV as string;
	// Extract the itineraries array from the imported data
	const itineraries: any[]
		= (toShow === "DEV") ?
			itinerary : [];

	const handleEdit = (itinerary: any) => {
		navigate(`/itineraries/${itinerary?.id}`, { state: { itinerary: itinerary } });
		console.log('itin', itinerary);
		console.log(`Edit button clicked for itinerary with id: ${itinerary?.id}`);
	};

	return (

		<Box mt={4}>
			<SearchBar
				onSearch={() => { }}
			/>
			<VStack spacing={'20px'} w={"-moz-fit-content"} mt={'32px'}>
				{itineraries.map((itinerary) => (
					<><ItineraryCardLayout
						key={itinerary?.id}
						thumbnail={itinerary?.trip_itinerary?.thumbnail}
						title={itinerary?.trip_itinerary?.title}
						dates={itinerary?.trip_itinerary?.dates}
						number_of_people={itinerary?.trip_itinerary?.number_of_people}
						onEdit={() => handleEdit(itinerary)} />
						<Divider
							my={2} // Margin Y for spacing
							border={'1px solid #FFFFFF21'} /></>
				))}

			</VStack>
		</Box>

	);
};

export default ListItinerary;
