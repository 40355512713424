import { Input, InputGroup, InputRightElement, Image, Box, useDisclosure, InputLeftElement, HStack, Heading } from "@chakra-ui/react";
import React, { useState } from "react";
import * as amplitude from '@amplitude/analytics-browser';
import ModalWithBackdrop from "../ModalWithBackdrop";
import SigninComponent from "../SigninComponent";


interface SearchBarProps {
  onSearch: (searchTerm: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showSuggestedBox, setShowSuggestedBox] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value); // Update the state with the input value
  };

  const handleSearch = () => {
    if (searchTerm.trim())
      onSearch(searchTerm); // Trigger the search when Enter is pressed or icon is clicked
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      amplitude.track('video_search_clicked', { type: "search_query", query_name: searchTerm, page: 'Homepage' });
      handleSearch();
    }
  };

  const suggestions = [
    'Japan travel suggestions',
    'New Zealand in December',
    'Top things to do in Thailand ',
    'Adventure activities in Switzerland',
  ];

  return (
    <>
      <Box>
        <HStack>
          <HStack w="50%" justifyContent="flex-start">
            <Heading
              fontFamily="Poppins"
              fontSize="18px"
              fontWeight="700"
              lineHeight="23.94px"
              letterSpacing="0px"
              textAlign="left"
              color="#FFFFFF">
              Search top travel videos
            </Heading>
          </HStack>
          <HStack w="50%" justifyContent="flex-end" pr={2} mb={"-2.8px"}>
            <Image src="/icons/lady.svg" alt="assest" />
          </HStack>
        </HStack>

        <InputGroup mt={'-1px'} w={'auto'} onFocus={(e) => {
          if (localStorage.getItem('token')) {
            setShowSuggestedBox(true)
          }
          else {
            (e.target as HTMLInputElement).blur();
            onOpen();
          }
        }} onBlur={() => setTimeout(() => setShowSuggestedBox(false), 200)}>

          <InputLeftElement

            // pl={6}
            mt={'4px'}
            children={
              <Image
                boxSize={'17px'}
                src={'/icons/reel.png'}
                alt="search"
                cursor="pointer"
                onClick={handleSearch} // Trigger search on icon click
              />
            }
          />
          <Input
            py={'3%'}
            placeholder="Discover inspiring travel videos"
            _placeholder={{ color: "#B0B0B0" }}
            bg="#242127"
            color="#FFFFFF"
            borderRadius="12px"
            border={'1px solid #FFFFFF12'}
            boxShadow={'0px 1px 1px rgba(255, 255, 255, 0.32)'}
            h={'48px'}
            pl={9}
            value={searchTerm} // Controlled input
            onChange={handleInputChange}
            onKeyDown={handleKeyDown} // Trigger search on Enter key press
          />
          <InputRightElement
            py={'25px'}
            mt={'1px'}
            children={
              <Image
                boxSize={'18px'}
                src={'/icons/search2.svg'}
                alt="search"
                cursor="pointer"
                onClick={() => {
                  amplitude.track('video_search_clicked', { type: "search_query", query_name: searchTerm, page: 'Homepage' });
                  handleSearch()
                }} // Trigger search on icon click
              />
            }
          />
        </InputGroup>
        {showSuggestedBox && (
          <Box
            position={"relative"}
            bg="#242127"
            color="gray.200"
            zIndex={99999}
            // w={"93vw"}
            p={2}
            borderRadius="12px"
            border="1px solid #FFFFFF12"
            maxH="auto"
            overflowY="auto"
          >
            {suggestions.map((suggestion, index) => (
              <Box
                key={index}
                p={2}
                fontSize={'md'}
                // _hover={{ bg: "#3a3a3a", cursor: "pointer" }}
                onClick={() => {

                  amplitude.track('video_search_clicked', { type: "suggested_query", query_name: suggestion, page: 'Homepage' });
                  setSearchTerm(suggestion);
                  onSearch(suggestion);
                }}
              >
                {suggestion}
              </Box>
            ))}
          </Box>
        )}

      </Box>
      <ModalWithBackdrop isOpen={isOpen} onClose={onClose}>
        <SigninComponent onClose={onClose} />
      </ModalWithBackdrop>
    </>
  );
};

export default SearchBar;
