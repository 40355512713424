import {
    Box,
    Image,
    Flex,
    Heading,
    Text,
    Textarea,
    FormControl,
    Button,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useLoading } from "../../context/LoadingContext";

interface ContactUsProps {
    countryName: string;
}

const Query: React.FC<ContactUsProps> = ({
    countryName
}) => {
    const { setIsLoading } = useLoading();


    // const data = {
    //     trip_itinerary:
    //     {
    //         "title": "Vacation",
    //         "id": "g54565133ee",
    //         "thumbnail": "https://example.com/thumbnail.jpg",
    //         "number_of_people": 4,
    //         "dates": "2023-12-01 to 2023-12-10",
    //         "duration": "10 days",
    //         "details": {
    //             "subtitle": "Family Vacation",
    //             "description": "A fun-filled family vacation to various destinations.A fun-filled family vacation to various destinations.A fun-filled family vacation to various destinations.A fun-filled family vacation to various destinations."
    //         },
    //         "days": [
    //             {
    //                 "day": 1,
    //                 "title": "Arrival and Check-in",
    //                 "accommodation": [
    //                     {
    //                         "name": "Hotel Sunshine",
    //                         "address": "123 Beach Road",
    //                         "costINR": 5000,
    //                         "occupancy": "Double",
    //                         "reasoning": "Close to the beach and affordable.",
    //                         "booking_link": "https://example.com/booking/hotel-sunshine"
    //                     },
    //                     {
    //                         "name": "Beachside Resort",
    //                         "address": "456 Ocean Drive",
    //                         "costINR": 6000,
    //                         "occupancy": "Suite",
    //                         "reasoning": "Luxurious stay with ocean view.",
    //                         "booking_link": "https://example.com/booking/beachside-resort"
    //                     }
    //                 ],
    //                 "activities": [
    //                     {
    //                         "type": "Sightseeing",
    //                         "name": "City Tour",
    //                         "description": "A guided tour of the city's main attractions.",
    //                         "costINR": "2000",
    //                         "notes": "Includes transportation and lunch.",
    //                         "booking_link": "https://example.com/booking/city-tour"
    //                     }
    //                     ,
    //                     {
    //                         "type": "Dining",
    //                         "name": "Welcome Dinner",
    //                         "description": "A special dinner at the hotel restaurant.",
    //                         "costINR": "3000",
    //                         "notes": "Includes a three-course meal and drinks.",
    //                         "booking_link": "https://example.com/booking/welcome-dinner"
    //                     },
    //                     {
    //                         "type": "Entertainment",
    //                         "name": "Evening Show",
    //                         "description": "A cultural show featuring local performances.",
    //                         "costINR": "1500",
    //                         "notes": "Show starts at 7 PM.",
    //                         "booking_link": "https://example.com/booking/evening-show"
    //                     }
    //                 ],
    //                 "eateries": [
    //                     {
    //                         "name": "Seafood Delight",
    //                         "preferred_for": "Dinner",
    //                         "cuisine": "Seafood",
    //                         "costINR": "1500",
    //                         "description": "A variety of fresh seafood dishes.",
    //                         "reasoning": "Highly recommended by locals.",
    //                         "booking_link": "https://example.com/booking/seafood-delight"
    //                     }
    //                 ],
    //                 "transportation": [
    //                     {
    //                         "type": "Taxi",
    //                         "costINR": "500",
    //                         "description": "From airport to hotel",
    //                         "from": "Airport",
    //                         "to": "Hotel Sunshine"
    //                     }
    //                 ],
    //                 "miscellaneous": "Welcome drinks at the hotel.",
    //                 "day_notes": "Make sure to rest well after the journey."
    //             },
    //             {
    //                 "day": 2,
    //                 "title": "Beach Day",
    //                 "accommodation": [
    //                     {
    //                         "name": "Hotel Sunshine",
    //                         "address": "123 Beach Road",
    //                         "costINR": 5000,
    //                         "occupancy": "Double",
    //                         "reasoning": "Close to the beach and affordable.",
    //                         "booking_link": "https://example.com/booking/hotel-sunshine"
    //                     }
    //                 ],
    //                 "activities": [
    //                     {
    //                         "type": "Leisure",
    //                         "name": "Beach Relaxation",
    //                         "description": "Relaxing day at the beach with optional water sports.",
    //                         "costINR": "1000",
    //                         "notes": "Includes beach chair and umbrella rental.",
    //                         "booking_link": "https://example.com/booking/beach-relaxation"
    //                     }
    //                 ],
    //                 "eateries": [
    //                     {
    //                         "name": "Beachside Grill",
    //                         "preferred_for": "Dinner",
    //                         "cuisine": "Grilled",
    //                         "costINR": "2000",
    //                         "description": "Grilled seafood and meats by the beach.",
    //                         "reasoning": "Great ambiance and fresh food.",
    //                         "booking_link": "https://example.com/booking/beachside-grill"
    //                     }
    //                 ],
    //                 "transportation": [
    //                     {
    //                         "type": "Walk",
    //                         "costINR": "0",
    //                         "description": "Walk from hotel to beach",
    //                         "from": "Hotel Sunshine",
    //                         "to": "Beach"
    //                     }
    //                 ],
    //                 "miscellaneous": "Beach games and activities.",
    //                 "day_notes": "Don't forget to apply sunscreen."
    //             }
    //         ],
    //         "budget_breakdown": {},
    //         "key_points": [
    //             "Pack light",
    //             "Carry sunscreen",
    //             "Stay hydrated"
    //         ],
    //         "assumptions": [
    //             "Weather will be pleasant",
    //             "All bookings are confirmed"
    //         ],
    //         "important_information": [
    //             "Keep your ID handy",
    //             "Emergency contact numbers"
    //         ],
    //         "excluded_activities_and_reasons": [
    //             "Skydiving - Too expensive",
    //             "Scuba diving - Not enough time"
    //         ]
    //     }
    // };

    const { fetchData } = useAxios();
    const [tripDetails, setTripDetails] = useState("");
    const navigate = useNavigate();
    const context: any = useContext(AppContext);
    const { likeEntities, setItinerary } = context;

    const filterEntriesByCountry = (likeEntities: { country?: string, [key: string]: any }[], countryName: string) => {
        return likeEntities
            .filter(entry => entry.country === countryName)
            .map(({ image, ...rest }) => rest);
    };

    const filteredEntries = filterEntriesByCountry(likeEntities, countryName);
    const sendQuery = async (message: string) => {


        // console.log("vsv", JSON.stringify(filteredEntries));
        let body = {
            query: message,
            bucketList: filteredEntries,
        };

        try {
            setIsLoading(true);
            const { data, statusCode } = await fetchData({
                method: "POST",
                url: `ai/trip_planner`,
                options: {
                    data: body,
                    headers: { "Content-Type": "application/json" },
                },
            });
            if (statusCode === 200) {
                if (data.error) {
                    alert(data?.error);
                    setTripDetails(message);
                    setIsLoading(false);
                } else {
                    setItinerary((prevItinerary: any[] = []) => [...prevItinerary, data]);
                    navigate(`/itineraries/${data?.id}`, { state: { itinerary: data } });
                }
            }
            else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
        finally {
            setIsLoading(false);
        }
    }

    const handleSend = async () => {
        if (!tripDetails.trim()) {
            alert("Please provide trip details before sending.");
            return;
        }
        else {
            const message = tripDetails;
            sendQuery(message);

            // navigate(`/itineraries/${data?.trip_itinerary?.id}`, { state: { itinerary: data?.trip_itinerary } });
            setTripDetails("");
        }
    };

    return (
        <Box
            width="100%"
            height="100vh"
            backgroundImage="/images/bg_images/Bg_4.png"
            position="relative"
            overflow="hidden"
            backgroundSize="cover"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            p={5}
        >
            <Flex mt={"10%"} mb={"10"} direction="column" gap={0} align={"center"}>
                <Flex direction="row" align={"center"} gap={4}>
                    <Image src="/icons/logo.svg" alt="logo" boxSize={"30px"} />
                    <Text
                        bgGradient="linear-gradient(90deg, #9BA1F2 0%, #F9EEC5 100%)"
                        bgClip={"text"}
                        fontSize={"26px"}
                        fontWeight={700}
                    >
                        Rahee AI
                    </Text>
                </Flex>
                <Text fontWeight={500} fontSize={"11px"} color={"#FFFFFFA1"} mt={"2px"}>
                    Start your journey with our specialised advisors
                </Text>

            </Flex>
            <Flex color={"#FFFFFFD0"} flexDir={"column"} gap={10}>
                <Flex flexDir={'column'} gap={3}>
                    <Heading fontSize={"18px"} mb={4} textAlign={'center'}>
                        Plan Your Perfect Trip with Us
                    </Heading>
                    <Text
                        fontWeight={500}
                        fontSize={"12px"}
                        color={"#FFFFFFD1"}
                        textAlign={"left"}
                    >
                        Example: Create a <b>5 day</b> itinerary to <b>Las Vegas</b> in <b>March</b>. I am planning to go with my <b>2 friends</b> for a luxury vacation. Our budget is <b>2,000,000 INR</b>.".
                    </Text>
                    <Text
                        fontWeight={500}
                        fontSize={"15px"}
                        color={"#FFFFFFD1"}
                        mt={"2px"}
                        textAlign={"left"}
                    >
                        Write your query to create a customised itinerary
                    </Text>
                    <FormControl>
                        <Textarea
                            fontSize={"13px"}
                            textAlign={"left"}
                            p={3}
                            color={"#FFFFFFC1"}
                            border={"1px solid grey"}
                            placeholder="Example: Create a 5 day itinerary to Las Vegas in March. I am planning to go with my 2 friends for a luxury vacation. Our budget is 2,000,000 INR."
                            value={tripDetails}
                            onChange={(e) => setTripDetails(e.target.value)}
                            _placeholder={{ fontSize: "12px" }}
                        />

                        <Button
                            fontSize={['15px', "19px", "23px"]}
                            mt={'4%'}
                            w={'40%'}
                            py={"20px"}
                            bg={"linear-gradient(90deg, #ACAFEB 0%, #E9E1CD 100%)"}
                            borderRadius={'100px'}
                            onClick={handleSend}
                        >
                            Generate
                        </Button>
                    </FormControl>
                </Flex>

            </Flex>

        </Box>
    );
};

export default Query;
