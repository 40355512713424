import {
    Box,
    HStack,
    Badge,
    IconButton,
    Collapse,
    useDisclosure,
    Stack,
    useBreakpointValue
} from '@chakra-ui/react';
import { MinusIcon, ChevronDownIcon } from '@chakra-ui/icons';
import React from 'react';
import GenericItineraryCard from './GenericItineraryCard';

interface ItineraryCardProps {
    dayItinerary: any;
}

const ItineraryCard: React.FC<ItineraryCardProps> = ({ dayItinerary }) => {
    const { isOpen, onToggle } = useDisclosure();
    const icon = useBreakpointValue({
        base: isOpen ? <MinusIcon boxSize={4} /> : <></>,
        md: <></>,
        lg: isOpen ? <></> : <ChevronDownIcon boxSize={4} />,
    });
    // console.log("dayItinerary", dayItinerary);

    return (
        <Stack bg={["#FFFFFF12", "#FFFFFF12", "none"]} mt={'24px'} w='full' borderRadius={'4px'} onClick={onToggle} _hover={{ cursor: "pointer" }}>
            <Box
                display={["none", "none", "block"]}
                borderTop={'1px solid #FFFFFF21'}
                mt={[4, 0, 0]}
            />
            <HStack
                alignItems='baseline'
                justifyContent="space-between"
                mx={[3, 3, 0]} mt={[isOpen ? 4 : 0, isOpen ? 4 : 2, isOpen ? 4 : 2]}
                mb={[isOpen ? 0 : 4, isOpen ? 0 : 4, 0]}>

                <Badge
                    bg={isOpen ? ['#AE7CFF', '#AE7CFF', 'none'] : ['#FFFFFF21', '#FFFFFF21', 'none']}  // Conditional color change only for small screens
                    color={["#FFFFFFD4", "#FFFFFFD4", "#FFFFFFED"]}
                    px={[4, 4, 0]}
                    py={2}
                    textTransform={"capitalize"}
                    rounded={['full', 'full', 'none']}  // Rounded only on small screens
                    fontSize={["13px", "13px", "33px"]}  // Apply larger font size for larger screens
                >
                    {`Day ${dayItinerary?.day}`}
                </Badge>
                <IconButton
                    aria-label={isOpen ? 'Collapse' : 'Expand'}
                    icon={icon}
                    bg={[isOpen ? '#FFFFFF0D' : 'none', 'none', 'none']}
                    color='#FFFFFFA1'
                    borderRadius='full'
                    onClick={onToggle}
                    _hover={{ bg: '#none' }}
                />
            </HStack>
            <Collapse in={isOpen} animateOpacity>
                <Box px={[4, 4, 0]}>
                    {/* {dayItinerary.map((item: any, index: number) => {
                        return (
                            <></>
                            // <GenericItineraryCard
                            //     key={index}
                            //     title={item.title}
                            //     subtitle={item.subtitle}
                            //     ImageCard={item.imageData}
                            //     locationFrom={item.locationFrom}
                            //     locationTo={item.locationTo}
                            //     Booking={item.Booking}
                            //     details={item.details}
                            //     LinkText={item.LinkText}
                            //     margin={item.margin}
                            //     onLinkButtonClick={item.onLinkButtonClick}
                            // />
                        );
                    }

                    )
                    } */}
                    <GenericItineraryCard
                        title={dayItinerary?.title}
                        accommodation={dayItinerary?.accommodation}
                    />
                    {/* <Box
                        borderBottom={'1px solid #FFFFFF21'}
                        mt={[4, 4, 8]}
                        mb={[4, 4, 8]}
                    /> */}
                    {dayItinerary?.transportation?.map((item: any, index: number) => (
                        <>
                        <Box
                            borderBottom={'1px solid #FFFFFF21'}
                            mt={[4, 4, 8]}
                            mb={[4, 4, 8]}
                        />
                        <GenericItineraryCard
                            key={index}
                            title={item?.description}
                            locationFrom={item?.from}
                            locationTo={item?.to}
                            type={item?.type}
                            costINR={item?.costINR}
                        />
                        </>
                    ))}
                    {dayItinerary?.activities && (
                        <>  <Box
                            borderBottom={'1px solid #FFFFFF21'}
                            mt={[4, 4, 8]}
                            mb={[4, 4, 8]}
                        />
                            <GenericItineraryCard
                                title='Activities'
                                activities={dayItinerary?.activities} />
                        </>
                    )}
                    {dayItinerary?.eateries && (
                        <>  <Box
                            borderBottom={'1px solid #FFFFFF21'}
                            mt={[4, 4, 8]}
                            mb={[4, 4, 8]}
                        />
                            <GenericItineraryCard
                                activities={dayItinerary?.eateries} />
                        </ >
                    )}

                    {dayItinerary?.miscellaneous && (
                        <>
                            <Box
                                borderBottom={'1px solid #FFFFFF21'}
                                mt={[4, 4, 8]}
                                mb={[4, 4, 8]}
                            />
                            <GenericItineraryCard
                                title='Miscellaneous'
                                details={dayItinerary?.miscellaneous}
                            />
                        </>

                    )}
                    {dayItinerary?.day_notes && (
                        <>
                            <Box
                                borderBottom={'1px solid #FFFFFF21'}
                                mt={[4, 4, 8]}
                                mb={[4, 4, 8]}
                            />
                            <GenericItineraryCard
                                title='Day Notes'
                                details={dayItinerary?.day_notes}
                            />
                        </>

                    )}
                </Box>
            </Collapse>
        </Stack>
    );
};

export default ItineraryCard;
