import { Box, Flex, Heading, HStack, SkeletonText, VStack, Text, Image, Button, Divider } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import Header from "../TravelAdvisor/Header";
import ChatCard from "../TravelAdvisor/ChatCard";
import InputField from "./InputField";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef } from "react";
import { AppContext } from "../../context/AppContext";
import 'swiper/css';
import 'swiper/css/effect-cards';
import './styles.css';

// import required modules
import { EffectCards } from 'swiper/modules';
import TripCard from "./TripCard";
import useTripService from "../../services/tripService";

const Conversation: React.FC = () => {
    const navigate = useNavigate();
    const { fetchConversationResponse } = useTripService();
    const context: any = useContext(AppContext);
    const { onBehalf,
        previousChat,
        isChatLoading
    } = context;
    const bottomRef = useRef<HTMLDivElement>(null);

    const handleChatButton = () => {
        navigate("/savedConversation");
    };

    useEffect(() => {
        // Scroll to bottom when chatEntries change
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [previousChat]);



    return (
        <Flex bg={"#FFFFFF"} h="100vh">
            {/* header and all */}
            <Box
                bg={"#FFFFFF"}
                position={"fixed"}
                top={0}
                left={0}
                w={"100vw"}
                zIndex={5}
            >
                <Header
                    backIconSrc="/icons/arrow_back.svg"
                    chatIconSrc="/icons/chat_filled.svg"
                    handleBackButton={() => { navigate(-1); }}
                    chatButtonOnClick={handleChatButton}
                    bgColor="#2F2F320D"
                    borderColor="linear-gradient(135deg, #FF7E5F, #FEB47B)"
                />

                {/* Chat Topic Bar */}
                <Flex
                    borderTop={"1px solid #3A3C4221"}
                    mt={"5px"}
                    pt={"8px"}
                    pb={"7px"}
                    pl={"18px"}
                    boxShadow={"0px 7px 7px 0px #00000008"}
                >
                    <Text
                        fontSize={"11px"}
                        fontFamily={"500"}
                        letterSpacing={"-0.47px"}
                        color={"#16181BA1"}
                    >
                        {/* {title} */}
                    </Text>
                </Flex>
            </Box>
            <Box bg={"#FFFFFF"} position={"fixed"} w="100vw" h="100vh">
                <Box
                    flex={1}
                    mt="25%"
                    overflowY="auto"
                    pt={4}
                    pb={'12%'}
                    mb={"10px"}
                    maxH="calc(100vh - 21vh)"
                >
                    <Box mt={"20px"}>
                        {previousChat?.map((entry: any, index: number) => (
                            <Flex
                                justifyContent={
                                    entry?.author === "user" ? "flex-end" : "flex-start"
                                }
                                key={index} // Added key here
                                mb={2} // Margin between entries
                            >
                                {isChatLoading &&
                                    entry.author === "assistant" &&
                                    entry?.text === "..." ? (
                                    <SkeletonText
                                        height="40px"
                                        width="40vw"
                                        borderRadius="8px"
                                        p={"10px"}
                                        noOfLines={3}
                                    />
                                ) : (
                                    <Flex
                                        direction={"column"}
                                        maxW={"100vw"}
                                        overflowX={"hidden"}

                                    >
                                        {/* For the Text */}

                                        <Flex
                                            mr={"12px"}
                                            ml={"12px"}
                                            justifyContent={
                                                entry.author === "user" ? "flex-end" : "flex-start"
                                            }>
                                            <Box
                                                borderRadius={"8px"}
                                                maxW={"227px"}
                                                mx={1}
                                                bg={
                                                    entry.author === "assistant" ? "#E8E9FF" : "#E0F0EA"
                                                }
                                            >
                                                {entry.onBehalf && (
                                                    <HStack borderRadius={"9px"} bg={"#F9FAFC"} h="72px" minW={'max-content'}>
                                                        <Image
                                                            src={entry.onBehalf.imageUrl}
                                                            alt={entry.onBehalf.title}
                                                            borderRadius={"12px 0px 0px 12px"}
                                                            objectFit={"cover"}
                                                            h={"72px"}
                                                            w={"50%"}
                                                        />
                                                        <Box py={"32px"} color={"#424242"}>
                                                            <VStack
                                                                spacing={0}
                                                                alignItems={"flex-start"}
                                                                ml={"2%"}
                                                                mt={0}
                                                            >
                                                                <Heading
                                                                    fontSize="12px"
                                                                    letterSpacing={"-0.5px"}
                                                                    fontWeight={700}

                                                                >
                                                                    {entry.onBehalf.title}
                                                                </Heading>
                                                                <Heading
                                                                    fontSize={"11px"}
                                                                    letterSpacing={"-0.5px"}
                                                                    fontWeight={400}
                                                                >
                                                                    {entry.onBehalf.subtitle}
                                                                </Heading>
                                                            </VStack>
                                                        </Box>
                                                    </HStack>)}
                                                {entry?.text && (<Text
                                                    fontFamily={"Poppins"}
                                                    color={"#16181B"}
                                                    fontSize={"14px"}
                                                    py={"10px"}
                                                    px={"12px"}
                                                    lineHeight={"19px"}
                                                    fontWeight={700}
                                                >
                                                    {entry?.text}
                                                </Text>)}
                                                {entry?.query_completed && (
                                                    <>
                                                        <Divider borderColor="white" />
                                                        <Button
                                                            border={"1px solid #AE7CFF"}
                                                            variant={'ghost'}
                                                            bg={'white'}
                                                        >
                                                            <Text
                                                                fontFamily={"Poppins"}
                                                                fontSize={"13px"}
                                                                lineHeight={"26.94px"}
                                                                fontWeight={600}
                                                                color={"#AE7CFF"}
                                                                onClick={() => {
                                                                    fetchConversationResponse("Confirm", true);
                                                                }}
                                                            >
                                                                Confirm
                                                            </Text>

                                                        </Button>
                                                    </>
                                                )}
                                            </Box>
                                        </Flex>
                                        {entry?.type === 'itinerary' && (
                                            <Box py="12px" ml={"10%"} w={"100%"} minW={"80dvw"} >
                                                <Swiper
                                                    effect={'cards'}
                                                    grabCursor={true}
                                                    modules={[EffectCards]}
                                                    className="mySwiper"
                                                    style={{ backgroundColor: "transparent !important" }}
                                                >
                                                    {entry?.itineraries?.map((entity: any, index: number) => (
                                                        <SwiperSlide
                                                            key={index}
                                                            style={{ background: 'transparent !important', boxShadow: 'none !important' }}
                                                        >
                                                            <TripCard
                                                                thumbnail={entity?.plan?.trip_itinerary?.thumbnail}
                                                                tripName={entity?.plan?.trip_itinerary?.title}
                                                                description={entity?.plan?.trip_itinerary?.description}
                                                                date={entity?.plan?.trip_itinerary?.dates}
                                                                price={entity?.plan?.trip_itinerary?.price}
                                                                onCardClick={() => {
                                                                    navigate(`/itineraries/${entity?.plan?.id}`, { state: { itinerary: entity?.plan } });
                                                                }}
                                                            />
                                                        </SwiperSlide>
                                                    ))}
                                                </Swiper>

                                            </Box>
                                        )}
                                        <Flex
                                            justifyContent={entry.author === "user" ? "flex-end" : "flex-start"}
                                        >
                                            <Text
                                                color={"#B5B5B5"}
                                                fontSize={"11px"}
                                                mt={1}
                                                mx={1}
                                                mr={"14px"}
                                                ml={"14px"}
                                                letterSpacing={"-0.47px"}
                                            >
                                                {entry.time}
                                            </Text>
                                        </Flex>
                                    </Flex>
                                )}
                            </Flex>
                        )
                        )}
                        <div ref={bottomRef} />
                    </Box>
                </Box>
            </Box>







            {/* Input Field */}
            < Box
                position={"fixed"}
                bottom={"10px"}
                bg={"white"}
                px={"31px"}
                py={3}
                w={"full"}
            >
                <InputField />
            </Box>


        </Flex>
    );
}
export default Conversation;