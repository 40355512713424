import { AppContext } from "../context/AppContext";
import useAxios from "../hooks/useAxios";
import { useCallback, useContext } from 'react';

const useTripService = () => {
    const { fetchData } = useAxios();
    const context: any = useContext(AppContext);
    const { conversationId, setConversationId, setPreviousChat, userDetail, setIsChatLoading } = context;

    const fetchConversationResponse = useCallback(async (
        message?: any,
        query_confirmed?: boolean
    ) => {
        try {
            setIsChatLoading(true);
            const meessageId = Date.now().toString(36) + Math.random().toString(36).substr(2);
            // console.log("messageId", new Date().toISOString());
            const mess = [{
                id: meessageId,
                text: message,
                type: "text",
                author: "user",
                time: new Date().toISOString(),
            }];
            console.log("message", mess);
            setPreviousChat((prevChat: any) => [...prevChat, mess?.[0]]);
            setPreviousChat((prevChat: any) => [...prevChat, { text: "...", author: "assistant", type: "text" }]);
            const { data, statusCode } = await fetchData({
                method: "POST",
                url: `ai/v1/itinerary`,
                options: {
                    data: {
                        user_id: userDetail?.user_id,
                        conversation_id: conversationId ?? "",
                        messages: mess ?? [],
                        query_confirmed: query_confirmed ?? false
                    },
                    headers: { "Content-Type": "application/json" },
                },
                auth: true,
            });
            if (statusCode === 200) {
                setConversationId(data?.conversation_id);

                const transformMessage = (message: any) => {
                    if (message.type === "itinerary" && message.itinerary) {
                        const { itineraries, message: text } = message.itinerary;

                        // Transform each itinerary: move `_id` to `plan.name`
                        const transformedItineraries = itineraries.map((itinerary: any) => {
                            const { _id, ...rest } = itinerary;
                            return {
                                ...rest,
                                plan: {
                                    ...itinerary.plan,
                                    id: _id,
                                },
                            };
                        });

                        return {
                            ...message,
                            itineraries: transformedItineraries,
                            text,
                        };
                    }

                    // Return the original message if type is not "itinerary"
                    return message;
                };

                const transformedMessage = transformMessage(data?.message);
                console.log(transformedMessage);

                await setPreviousChat((prevChat: any) => {
                    const newChat = [...prevChat];
                    if (newChat.length > 0) {
                        newChat[newChat.length - 1] = { ...newChat[newChat.length - 1], ...transformedMessage };
                    } else {
                        newChat.push({ ...transformedMessage });
                    }
                    return newChat;
                });
                if (data?.query_completed) {
                    setPreviousChat((prevChat: any) => {
                        const newChat = [...prevChat];
                        if (newChat.length > 0) {
                            newChat[newChat.length - 1] = { ...newChat[newChat.length - 1], query_completed: data?.query_completed };
                        }
                        return newChat;
                    });
                }
                setIsChatLoading(false);
            }
        } catch (error) {
            setIsChatLoading(false);
            console.error("Fetch error:", error);
        }
    }, [fetchData, setPreviousChat]); // Add fetchData and setPreviousChat to the dependency array

    return { fetchConversationResponse };
};

export default useTripService;