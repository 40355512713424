import VideoHighlightsSection from '../components/VideoHighlightsSection';
import { AspectRatio, Box, Flex, Image, Skeleton } from '@chakra-ui/react';
import TextHeader from '../components/TextHeader';
import LocationText from '../components/LocationText';
import NameAvatar from '../components/NameAvatar';
import LocationDescriptionText from '../components/LocationDescriptionText';
import MoreFromInfluencerSection from '../components/MoreFromInfluencerSection';
import MoreAboutPlaceSection from '../components/MoreAboutPlaceSection';
import ItinerarySection from '../components/ItinerarySection';
import { useEffect, useState } from 'react';
import axios from 'axios';

const URL = 'http://127.0.0.1:5000';
const Youtube_Url =
	'https://www.xyz.com/watch?v=rdcJJYr8AK0&ab_channel=Top10Places';

const Home = () => {
	const [videoId, setVideoId] = useState('');
	const [videoTitle, setVideoTitle] = useState('');
	const [, setSummary] = useState('');
	const [channelName, setChannelName] = useState('');

	useEffect(() => {
		const fetchData = async () => {
			try {
				const body = {
					url: Youtube_Url,
				};

				const res = await axios.post(
					`${URL}/api/SummaryExtractionParallel/`,
					body,
				);
				const data = res['data'];
				console.log(data);
				setVideoTitle(data['title']);
				setSummary(data['transcript_summary']);
				setChannelName(data['channel_name']);
				const s = data['video_metadata_id'];
				const splits = s.split('&');
				setVideoId(splits[0]);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, []);

	const text =
		'London, the capital city of the United Kingdom, is a vibrant metropolis rich in history and culture ondon, the capital city of the United Kingdom, is a vibrant metropolis rich in history and culture';
	return (
		<Flex
			position='relative'
			flexDirection={'column'}
			width='100%'
			height='100vh'
			background={'#fff'}
			sx={{
				overflowY: 'auto !important',
				scrollbarWidth: 'none',
			}}>
			<Box>
				<AspectRatio>
					<iframe
						src={`https://www.xyz.com/embed/${videoId}`}
						title='travel-video'
					/>
				</AspectRatio>
			</Box>
			<Flex flexDir={'column'} m='16px'>
				<TextHeader>{videoTitle}</TextHeader>
				<Flex
					mt='8px'
					w='100%'
					justifyContent={'space-between'}
					flexDirection={'row'}>
					<NameAvatar name={channelName} avatarUrl='' />
					<LocationText>London, Uk</LocationText>
				</Flex>

				<LocationDescriptionText text={text} sx={{ marginTop: '16px' }} />

				<Skeleton isLoaded={false}>
					<Image
						mt='16px'
						src='./images/placeholder-map.png'
						alt='placeholder-map'
					/>
				</Skeleton>
			</Flex>
			<VideoHighlightsSection />
			<ItinerarySection />
			<MoreFromInfluencerSection />
			<MoreAboutPlaceSection />
		</Flex>
	);
};

export default Home;
